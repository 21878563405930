import { Link } from "gatsby"
import React from "react"
import ImageLoader from "./imageLoader"

const ClientCard = ({ client, grow, slider }) => {
  console.log(client)

  //const image = getImage(client.clientDetails.heroImage.localFile)

  //console.log(image)

  return (
    <Link to={client.uri} className="text-black">
      <div
        style={{ objectFit: "cover" }}
        className={
          "  border-black border-r-2 object-cover relative " +
          (!slider ? "flex-grow h-full" : "flex-shrink-0 slider slide-items")
        }
      >
        {/* //w-auto h-64 sm:h-80 md:h-96  */}
        <div className="absolute z-50 h-full w-full bg-createbeige hover:bg-createneongreen hover:opacity-70 opacity-50"></div>
        <div className="absolute z-50 p-6 w-full opacity-100 pointer-events-none uppercase text-xl sm:text-2xl 2xl:text-3xl bottom-0">
          {client.title}
        </div>
        <ImageLoader
          image={client.clientDetails.heroImage}
          // alt={client.clientDetails.heroImage.altText}
          // className="border-black border-r-2"
          objectFit="cover"
          imgStyle={{ objectFit: "cover" }}
          className="object-cover h-full"
        />
        {/* <img
        src="https://picsum.photos/1000/1000"
        draggable="false"
        className="object-cover"
      /> */}
      </div>
    </Link>
  )
}

export default ClientCard
