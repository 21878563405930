import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ClientCard from "../components/clientCard"
import Layout from "../components/layout"
import Testimonials from "../components/testimonials"

import Seo from "gatsby-plugin-wpgraphql-seo"

const OurWork = () => {
  const clients = useStaticQuery(graphql`
    query {
      allWpClient(sort: { order: ASC, fields: menuOrder }) {
        nodes {
          id
          title
          uri
          menuOrder
          clientDetails {
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      wpPage(id: { eq: "cG9zdDo3OTEz" }) {
        id
        title
        nodeType
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo post={clients.wpPage} />
      <div className="mx-auto max-w-screen-2xl">
        <div className="grid lg:grid-cols-3 border-black border-l-2 ">
          {clients.allWpClient.nodes.map((item, i) => {
            if (i <= 2) {
              return (
                <div className="border-b-2 border-black">
                  <ClientCard client={item} grow />
                </div>
              )
            }
          })}
        </div>
        <div className="grid lg:grid-cols-4 border-l-2 border-black">
          <div className="lg:col-span-2 border-black border-r-2 border-b-2">
            <Testimonials />
          </div>
          {clients.allWpClient.nodes.map((item, i) => {
            if (i > 2 && i < 5)
              return (
                <div className="border-black  border-b-2">
                  <ClientCard client={item} grow />
                </div>
              )
          })}
        </div>
        <div className="grid lg:grid-cols-3 border-l-2 border-black">
          {clients.allWpClient.nodes.map((item, i) => {
            if (i >= 5 && i < 8)
              return (
                <div className="border-black border-b-2">
                  <ClientCard client={item} grow />
                </div>
              )
          })}
        </div>
        <div className="grid lg:grid-cols-5 border-l-2 border-black">
          {clients.allWpClient.nodes.map((item, i) => {
            if (i >= 8)
              return (
                <div className="border-black border-b-2">
                  <ClientCard client={item} grow />
                </div>
              )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default OurWork
