import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

const Testimonials = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const about = useStaticQuery(graphql`
    query {
      allWpTestimonial(sort: { order: ASC, fields: menuOrder }) {
        edges {
          node {
            title
            testimonials {
              testimonialBody
            }
          }
        }
      }
    }
  `)

  const updateSlide = prev => {
    console.log(about.allWpTestimonial.edges.length - 1)
    if (currentSlide === about.allWpTestimonial.edges.length) {
      setCurrentSlide(0)
      return
    }
    if (currentSlide === 0) {
      setCurrentSlide(about.allWpTestimonial.edges.length - 1)
      return
    }

    if (!prev) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(currentSlide - 1)
    }
  }

  return (
    <div className="flex flex-col h-full min-h-[40rem] md:min-h-[25rem] lg:min-h-[30rem] 2xl:min-h-[20rem]">
      <div>
        <h2 className="uppercase p-6">Testimonials</h2>
      </div>
      <div className="flex-grow p-6 pb-10 pt-0 relative">
        {
          about.allWpTestimonial.edges[currentSlide].node.testimonials
            .testimonialBody
        }
        <div className="uppercase font-semibold text-right bottom-2 right-2 absolute">
          - {about.allWpTestimonial.edges[currentSlide].node.title}
        </div>
      </div>
      <div className="border-black border-t-2 relative h-16">
        <button
          className="border-r-2 border-black absolute left-0 bottom-0 p-5 text-createneongreen"
          onClick={() => updateSlide()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          className="border-l-2 border-black absolute right-0 bottom-0 p-5 text-createneongreen"
          onClick={() => updateSlide("prev")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Testimonials
